import 'reflect-metadata';

import Link from 'next/link';
import Router from 'next/router';
import { Component } from 'react';
import InternalMotiveComponent from '../../components/ui/motive/internal-motive.component';
import { MotiveComponent } from '../../components/ui/motive/motive.component';
import { IOC_TYPES } from '../../context/ioc.types';
import { lazyInject } from '../../context/module';
import { CapabilityService } from '../../services/capability.service';
import { EnvironmentService } from '../../services/environment.service';
import { MotivesService } from '../../services/motives.service';
import { ScriptLoadService } from '../../services/script-load.service';
import { WebSocketService } from '../../services/websocket.service';
import { WindowService } from '../../services/window.service';
import { BrowserUtils } from '../../ui-utils/browser-utils';
import { groupBy, includes } from '../../utils/lodash-min';
import { MotiveConfig, MotiveLocationTypes } from '../../utils/request.utils';
import { StringUtils } from '../../utils/string-utils';
import Dropdown from '../ui/dropdown/dropdown';
import styles from './home.component.module.scss';


interface DisplayableCategory {
    name: string;
    link: string;
    subcategories: DisplayableSubcategory[];
}

interface DisplayableSubcategory {
    name: string;
    link: string;
    features: DisplayableFeature[];
}

interface DisplayableFeature {
    title: string;
    link: string;
    description: string;
    searchWords: string[];
}

interface HomeComponentState {
    selectedCategory: DisplayableCategory;
    selectedSubcategory: DisplayableSubcategory;
    searchKeywords: string[]
}

export default class HomeComponent extends Component<any, HomeComponentState> {
    showHistory = true;
    siteHistory = '';
    effectiveMotives: MotiveConfig;

    internalAdsEnabled: boolean;
    isDomainSupportOfflineProcessing: boolean;

    featuredItems: DisplayableFeature[];
    featureCategories: DisplayableCategory[];

    @lazyInject(IOC_TYPES.CapabilityService) capabilityService: CapabilityService;
    @lazyInject(IOC_TYPES.EnvironmentService) envService: EnvironmentService;
    @lazyInject(IOC_TYPES.WindowService) public windowService: WindowService;
    @lazyInject(IOC_TYPES.ScriptLoadService) private scriptLoader: ScriptLoadService;
    @lazyInject(IOC_TYPES.MotivesService) private motivesService: MotivesService;
    @lazyInject(IOC_TYPES.WebSocketService) private webSocketService: WebSocketService;

    readonly state: Readonly<HomeComponentState> = this.initializeFeatureDataAndGetState();
    constructor(props) {
        super(props);
        this.motivesService.onEffectiveMotiveSettingsChange.subscribe(
            (motives) => {
                this.effectiveMotives = motives;
                this.internalAdsEnabled = this.effectiveMotives && this.effectiveMotives.internalMotivesEnabled;
            },
            (error) => console.error(error),
        );

        if (this.envService.env.showHistory) {
            this.siteHistory = props.history;
        }
    }

    initializeFeatureDataAndGetState(): HomeComponentState {
        this.updateFeaturedItems();
        this.updateFeatureCategoriesWithChildren();
        return {
            selectedCategory: this.featureCategories[0],
            selectedSubcategory: this.featureCategories[0].subcategories[0],
            searchKeywords: []
        };
    }

    updateFeaturedItems() {
        const featuredItemTitles = [
            'compress-mp4',
            'video-compressor',
            'resize-image',
            'compress-jpeg',
            'merge-image-to-pdf',
            'audio-converter',
        ];
        const featuredConverters = this.capabilityService.getConverters().filter(item => {
            return includes(featuredItemTitles, item.id);
        });

        this.featuredItems = featuredConverters.map(converter => {
            return {
                title: converter.title,
                link: converter.link,
                description: converter.description,
                searchWords: converter.title.toLowerCase().split(" ")
            };
        });
    }



    updateFeatureCategoriesWithChildren() {
        var featureCategories: DisplayableCategory[] = []
        const converters = this.capabilityService.getConverters();
        const convertersGroupedByType = groupBy(converters, (item) => item.type);

        const displayableSubcategoryName = () => {

        }

        const displayableFeatureName = () => {

        }

        const displayableCategoryName = (type) => {
            if (includes(['encode', 'decode',], type)) {
                return StringUtils.toTitleCase(type);
            }

            if (includes(['count'], type)) {
                return `Word ${StringUtils.toTitleCase(type)} Tools`;
            }

            if (includes(['meme'], type)) {
                return `${StringUtils.toTitleCase(type)} Tools`;
            }

            return `${StringUtils.toTitleCase(type)} Tools`
        }
        featureCategories.push({
            name: 'All',
            link: `/tools`,
            subcategories: [
                {
                    name: 'All',
                    link: `/tools`,
                    features: [],
                }
            ],
        });
        Object.keys(convertersGroupedByType).forEach(type => {
            const featureCategory: DisplayableCategory = {
                name: displayableCategoryName(type),
                link: `/tools/${type}`,
                subcategories: [
                    {
                        name: 'All',
                        link: `/tools`,
                        features: [],
                    }
                ],
            }
            featureCategories.push(featureCategory);

            const convertersForType = convertersGroupedByType[type];
            const converterTypesGroupedByToType = groupBy(convertersForType, (item) => item.from);
            Object.keys(converterTypesGroupedByToType).forEach(to => {
                const featureSubcategory: DisplayableSubcategory = {
                    name: StringUtils.toTitleCase(to),
                    link: `/tools/${type}-${to}`,
                    features: [],
                }
                featureCategory.subcategories.push(featureSubcategory);
                const converters = converterTypesGroupedByToType[to];
                converters.forEach(converter => {
                    const feature: DisplayableFeature = {
                        title: converter.title,
                        description: converter.description,
                        link: converter.link,
                        searchWords: converter.title.toLowerCase().split(" ")
                    }
                    featureSubcategory.features.push(feature);
                    featureCategory.subcategories[0].features.push(feature);
                    featureCategories[0].subcategories[0].features.push(feature);
                })
            });
        });

        this.featureCategories = featureCategories;
    }

    onCategorySelect(selectedCategory: DisplayableCategory) {
        this.setState({
            selectedCategory: selectedCategory,
            selectedSubcategory: selectedCategory.subcategories[0],
        })
    }

    onSubcategorySelect(selectedSubcategory: DisplayableSubcategory) {
        this.setState({
            selectedSubcategory: selectedSubcategory,
        })
    }

    navigateToLink(link) {
        if (BrowserUtils.hasWindow()) {
            // const router = useRouter();
            Router.push(link);
        }
    }

    filterFeatures(e: string) {
        const keyword = (e || '').toLowerCase();
        if(!keyword.length) {
            this.setState({
                searchKeywords: []
            });
            return;
        }
        this.setState({
            searchKeywords: keyword.split(" ")
        })
    }

    clearFilter() {
        this.setState({
            searchKeywords: []
        });
    }

    private getFilteredFeatureItems() {
        var max = 1;
        return this.state.selectedSubcategory.features.map((feature) => {
            if(!this.state.searchKeywords.length) {
                max = Math.max(max, 1);
                return {
                    feature: feature,
                    matches: 1,
                };
            }
            let set = this.state.searchKeywords.filter(sk => feature.searchWords.find(a => a.match(new RegExp(sk))));
            max = Math.max(max, set.length);
            return {
                feature: feature,
                matches: set.length,
                max: Math.max(max, set.length)
            };
        }).filter(a => a.matches === max).sort((a, b) => b.matches - a.matches).map(({feature}, index) => {
            return <div key={`featured-item-${index}`} className={styles['featured-item']}>
                <Link href={feature.link} className={styles['featured-item-title']}>{feature.title}</Link>
                <div className={styles['featured-item-description']}>{feature.description}</div>
            </div>
        })
    }

    render() {
        return <div className="pe-2 ps-2 mt-3">
            <div className="gcontent-container">
                <MotiveComponent
                    data="7590989337" containerWidth="100%" containerHeight="90px"
                    location={MotiveLocationTypes.homePageTop}
                    adWidth="970px"
                    adHeight="90px" showAds={true} className='width_major'
                >
                </MotiveComponent>
                <InternalMotiveComponent
                    internalMotiveId="728"
                    containerWidth="100%" containerHeight="90px" adWidth="970px" adHeight="90px" showAds={true}
                ></InternalMotiveComponent>
            </div>

            <div className={styles['sections']}>
                <section className={styles['most-used-tools']}>
                    <h2>Most Used Tools</h2>
                    <div className={styles['featured-items']}>
                        {
                            this.featuredItems.map((featuredItem, index) => {
                                return <div key={`feature-item-${index}`} className={styles['featured-item']}>
                                    <Link href={featuredItem.link} className={styles['featured-item-title']}>
                                        {featuredItem.title}
                                    </Link>
                                    <div className={styles['featured-item-description']}>{featuredItem.description}</div>
                                </div>
                            })
                        }
                    </div>
                </section>
                <section className={styles['all-features']}>
                    <h2>All Categories</h2>
                    <div className={styles['feature-categories']}>
                        {
                            this.featureCategories.map((featureCategory, index) => {
                                return <div key={`feature-category-${index}`} className={styles['feature-category']}>
                                    <span className={styles['feature-category-title']} onClick={() => this.onCategorySelect(featureCategory)}>
                                        {featureCategory.name}
                                    </span>
                                    <Link href={featureCategory.link} className={`x-btn x-btn-gray x-btn-gray-outline x-btn-svg`}>
                                        <img src="/icons/svg/box-arrow-up-right.svg" />
                                    </Link>
                                </div>
                            })
                        }

                    </div>

                    <div className={styles['feature-categories-mobile']}>
                        {
                            <Dropdown
                                initialValue={this.featureCategories[0]}
                                values={this.featureCategories}
                                valueFormatter={(v) => v && v.name}
                                hasTooltip={(v) => false}
                                getTooltip={(v) => ''}
                                emitInitialValueOnInit={true}
                                shownFn={(v) => true}
                                onSelect={(featureCategory) => featureCategory && this.onCategorySelect(featureCategory)}
                            >
                            </Dropdown>
                        }

                    </div>
                    {
                        this.state.selectedCategory && this.state.selectedCategory.subcategories.length > 2 && <div className={styles['feature-subcategories']}>
                            {
                                this.state.selectedCategory.subcategories.map((subcategory, index) => {
                                    return <span key={`feature-subcategory-${index}`} className={styles['feature-subcategory']} onClick={() => this.onSubcategorySelect(subcategory)}>
                                        <span className={styles['feature-subcategory-title']}>{subcategory.name}</span>
                                    </span>
                                })
                            }
                        </div>
                    }
                </section>
                <section>
                    <div className="mb-3">
                        <div className="row">
                            <div className="col-12" style={{ display: 'flex', gap: '0.4rem' }}>
                                <input type="Search" id="inputSesarch6" className="form-control" aria-describedby="SearchHelpInline" onInput={(e: any) => this.filterFeatures(e.target?.value)} />
                                <button className='btn btn-secondary' onClick={() => this.clearFilter()}>Clear</button>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    this.state.selectedSubcategory && <section >
                        <h4>{this.state.selectedSubcategory.name === 'All' ? 'All Features' : 'Filtered Features'}</h4>
                        <div className={styles['featured-items']}>
                            {
                                this.getFilteredFeatureItems()
                            }
                            {
                                <div key={`featured-item-unit-convert`} className={styles['featured-item']} onClick={() => this.navigateToLink("/unit-converter")}>
                                    <Link href="/unit-converter" className={styles['featured-item-title']}>Unit Converter</Link>
                                    <div className={styles['featured-item-description']}>Convert units suchs as Bytes, Meters, Grams and more...</div>
                                </div>
                            }
                        </div>
                    </section>
                }

                {
                    this.siteHistory && <div className="mt-3" dangerouslySetInnerHTML={{ __html: this.siteHistory }}>

                    </div>
                }
            </div>
        </div>
    }
}
